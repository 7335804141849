<template>
	<div>
		<template>
		  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
		    <el-tab-pane label="代收方式管理" name="first">
				<el-button class="mb10" size="mini" type="primary" icon="el-icon-plus" @click="addAccount('new')">新增</el-button>
				<el-table :max-height="height" :data="tableData" border stripe v-loading="loading" style="width: 700px; max-height: 500px;"  size="mini">
					<el-table-column prop="name" :label="$t('main.passagewayMenu.collectionMethod')" align="center">
						<template slot-scope="scope">
							【{{scope.row.id}}】{{scope.row.name}}
						</template>
					</el-table-column>
					<el-table-column  :label="$t('main.passagewayMenu.state')" align="center">
						<template slot-scope="scope">
							<el-switch
							  v-model="scope.row.state== 0?true:false"
							  active-color="#13ce66"
							  inactive-color="#ff4949"
							  @change="changeState(scope.row,'pay')"
							  :width="30"
							  active-text="启用"
							  inactive-text="停用"
							  >
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope">
							  <el-link class="mr10" :underline="false" size="mini" icon="el-icon-edit" @click="addAccount('edit', scope.row)">编辑</el-link>
							  
							  <el-popconfirm
							    title="确定删除吗？"
								@confirm="delPay(scope.row)"
							  >
								<el-link slot="reference" :underline="false" size="mini" icon="el-icon-delete">删除</el-link>
							  </el-popconfirm>
							  
						</template>
					</el-table-column>
				</el-table>
				
			</el-tab-pane>
		    <el-tab-pane label="代付方式管理" name="second">
				<el-button class="mb10" size="mini" type="primary" icon="el-icon-plus" @click="addAccount('newRepay')">新增</el-button>
				<el-table :max-height="height" :data="repayTableData" border stripe v-loading="loading" style="width: 700px; max-height: 500px;"  size="mini">
					<el-table-column :label="$t('main.passagewayMenu.paymentMethod')" align="center">
						<template slot-scope="scope">
							【{{scope.row.id}}】{{scope.row.name}}
						</template>
					</el-table-column>
					<el-table-column  :label="$t('main.passagewayMenu.state')" align="center">
						<template slot-scope="scope">
							<el-switch
							  v-model="scope.row.state== 0?true:false"
							  active-color="#13ce66"
							  inactive-color="#ff4949"
							  @change="changeState(scope.row,'repay')"
							  :width="30"
							  active-text="启用"
							  inactive-text="停用"
							  >
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('main.passagewayMenu.operation')" align="center">
						<template slot-scope="scope">
							<el-link class="mr10" :underline="false" size="mini" icon="el-icon-edit" @click="addAccount('editRepay', scope.row)">编辑</el-link>
							  
							  <el-popconfirm
							    title="确定删除吗？"
								@confirm="delRepay(scope.row)"
							  >
								<el-link slot="reference" :underline="false" size="mini" icon="el-icon-delete">删除</el-link>
							  </el-popconfirm>
							  
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		  </el-tabs>
		</template>
		
		<div>
			<!-- <el-dialog class="dialog" top="200px" :title="title" :visible.sync="dialogVisible" @close="close"
			 :close-on-click-modal="false" center>
				<div class="formBox" v-loading="dialogloading">
					<div class="mb20">
						<el-form :label-position="labelPosition" label-width="80px">
							<el-form-item :label="$t('main.passagewayMenu.collectionMethod')">
								<span>{{item.name}}</span>
							</el-form-item>
							<el-form-item label="手续费">
								<el-input v-model="fee" placeholder="请输入手续费"></el-input>
							</el-form-item>
							
						</el-form>
					</div>
					<div class="textCenter"><el-button type="primary" size="mini" @click="payTypeSetFee()">确定</el-button></div>
				</div>
			</el-dialog> -->
			
			<el-dialog center class="dialog" top="100px" :title="title" :visible.sync="dialogVisible" @close="close":close-on-click-modal="false" width="30%">
				<div class="formBox" v-loading="dialogloading">
					<div style="width: 80%;">
						
						<el-form size="mini" :label-position="labelPosition" label-width="100px" v-if="type === 'new' || type === 'edit'">
							<el-form-item label="编号">
								<el-input :disabled="type=='edit'" v-model="form.id" placeholder="请输入编号"></el-input>
							</el-form-item>
							<el-form-item label="支付方式名称">
								<el-input  v-model="form.name" placeholder="请输入支付方式名称"></el-input>
							</el-form-item>
							<el-form-item label="状态">
								<el-switch
								  v-model="form.state"
								  active-color="#13ce66"
								  inactive-color="#ff4949"
								  :width="30"
								  active-text="启用"
								  inactive-text="停用"
								  >
								</el-switch>
							</el-form-item>
						</el-form>
						
						<el-form size="mini" :label-position="labelPosition" label-width="100px" v-if="type === 'newRepay' || type === 'editRepay'">
							<el-form-item label="编号">
								<el-input :disabled="type=='edit'" v-model="form.id" placeholder="请输入编号"></el-input>
							</el-form-item>
							<el-form-item label="代付方式名称">
								<el-input  v-model="form.name" placeholder="请输入代付方式名称"></el-input>
							</el-form-item>
							<el-form-item label="状态">
								<el-switch
								  v-model="form.state"
								  active-color="#13ce66"
								  inactive-color="#ff4949"
								  :width="30"
								  active-text="启用"
								  inactive-text="停用"
								  >
								</el-switch>
							</el-form-item>
						</el-form>
					</div>
					<div class="textCenter">
						<el-button type="primary" @click="onSubmit">{{submitText}}</el-button>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				tableData:[],
				item:{},
				title:'设置费率',
				dialogVisible:false,
				dialogloading:false,
				labelPosition:'right',
				fee:'',
				height:0,
				activeName: 'first',
				repayTableData: [],
				// 通道
				form:{
					id:'',
					name:'',
					state:true,
				},
				submitText:'确认',
				type:'',
			}
		},
		created() {
			this.payTypeAll()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 120
			})
		},
		methods:{
			delPay(item){
				let data = {
					id: item.id,
				}
				this.$api.delPayType(data).then(res => {
					this.loading = false
					// console.log(res)
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.payTypeAll()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				
				}).catch(error => {
					this.loading = false
				})
			},
			delRepay(item){
				let data = {
					id: item.id,
				}
				this.$api.delRepayType(data).then(res => {
					this.loading = false
					// console.log(res)
					if (res.status === 1) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.repayTypeAll()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				
				}).catch(error => {
					this.loading = false
				})
			},
			changeState(item, type) {
				let data = {
					id: item.id,
					name: item.name,
				    state: item.state==0?1:0
				}
				if(type == 'pay'){
					this.loading = true
					this.$api.editPayType(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.payTypeAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					
					}).catch(error => {
						this.loading = false
					})
				}
				if(type == 'repay'){
					this.loading = true
					this.$api.editRepayType(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.repayTypeAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					
					}).catch(error => {
						this.loading = false
					})
				}
			},
			//查看列表
			payTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.payTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.tableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			payTypeSetState(item){
				let data = {
					pay_type:item.id,
					state:item.state==0?1:0,
				}
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.payTypeSetState(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'info',
								message: this.$t('main.common.successConfirm')
							});
							this.payTypeAll()
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}
				
					}).catch(error=>{
						this.loading = false
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('main.common.cancelConfirm')
					});
				});
			},
			//新增
			onSubmit() {
				if (this.type === 'new') {
					//新增商家
					if (isEmpty(this.form.id, '请输入编号')) {
						return
					}
					if (isEmpty(this.form.name, '请输入支付名称')) {
						return
					}
					let data = {
						id: this.form.id,
						name: this.form.name,
						state: this.form.state==true?0:1,
					}
					this.dialogloading = true
					this.$api.addPayType(data).then(res => {
						console.log(res)
						this.dialogloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							});
							this.close();
							this.page = 1;
							this.payTypeAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}
				else if(this.type == 'edit'){
						//编辑商家
						if (isEmpty(this.form.id, '请输入编号')) {
							return
						}
						if (isEmpty(this.form.name, '请输入支付名称')) {
							return
						}
						let data = {
							id: this.form.id,
							name: this.form.name,
							state: this.form.state==true?0:1,
						}
						this.dialogloading = true
						this.$api.editPayType(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								});
								this.close();
								this.page = 1;
								this.payTypeAll()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								});
							}
						}).catch(error => {
							this.dialogloading = false
						})
				}
				else if(this.type == 'newRepay'){
						//编辑商家
						if (isEmpty(this.form.id, '请输入编号')) {
							return
						}
						if (isEmpty(this.form.name, '请输入代付名称')) {
							return
						}
						let data = {
							id: this.form.id,
							name: this.form.name,
							state: this.form.state==true?0:1,
						}
						this.dialogloading = true
						this.$api.addRepayType(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								});
								this.close();
								this.page = 1;
								this.repayTypeAll()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								});
							}
						}).catch(error => {
							this.dialogloading = false
						})
				}
				else if(this.type == 'editRepay'){
						//编辑商家
						if (isEmpty(this.form.id, '请输入编号')) {
							return
						}
						if (isEmpty(this.form.name, '请输入代付名称')) {
							return
						}
						let data = {
							id: this.form.id,
							name: this.form.name,
							state: this.form.state==true?0:1,
						}
						this.dialogloading = true
						this.$api.editRepayType(data).then(res => {
							this.dialogloading = false
							if (res.status === 1) {
								this.$message({
									type: 'success',
									message: res.msg
								});
								this.close();
								this.page = 1;
								this.repayTypeAll()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								});
							}
						}).catch(error => {
							this.dialogloading = false
						})
				}
			},
			addAccount(type,item) {
				this.dialogVisible = true
				this.type = type
				if (item) {
					this.item = item;
				}
				if (this.type === 'new') {
					this.title = '新增支付方式'
					this.submitText = '新增'
				}
				else if(this.type == 'edit'){
					this.form.id = item.id
					this.form.name = item.name
					this.form.state = item.state==0?true:false
					this.title = '编辑支付方式'
					this.submitText = '确定'
				}
				else if(this.type == 'newRepay'){
					this.title = '新增代付方式'
					this.submitText = '新增'
				}
				else if(this.type == 'editRepay'){
					this.form.id = item.id
					this.form.name = item.name
					this.form.state = item.state==0?true:false
					this.title = '编辑代付方式'
					this.submitText = '确定'
				}
			},
			close(){
				this.item = {};
				this.dialogVisible = false;
				this.form.id = ''
				this.form.name = ''
				this.form.state = true
			},
			handleClick(tab, event){
				if(this.activeName == 'first'){ // 代收
					this.repayTableData = []
					this.payTypeAll()
				}
				if(this.activeName == 'second'){ // 代付
					this.tableData = []
					this.repayTypeAll()
				}
			},
			// 代付方式列表
			repayTypeAll() {
				let data = {}
				// this.loading = true
				this.$api.repayTypeAll(data).then(res => {
					this.loading = false
					if (res.status == 1) {
						this.repayTableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			
				}).catch(error => {
					this.loading = false
				})
			},
			
			repayTypeSetState(item){
				let data = {
					pay_type:item.id,
					state:item.state==0?1:0,
				}
				this.$confirm(this.$t('main.common.confirm'), this.$t('main.common.prompt'), {
					confirmButtonText: this.$t('main.common.submit'),
					cancelButtonText: this.$t('main.common.cancel'),
					type: 'warning'
				}).then(() => {
					this.loading = true
					this.$api.repayTypeSetState(data).then(res => {
						this.loading = false
						// console.log(res)
						if (res.status === 1) {
							this.$message({
								type: 'info',
								message: this.$t('main.common.successConfirm')
							});
							this.repayTypeAll()
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}
				
					}).catch(error=>{
						this.loading = false
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: this.$t('main.common.cancelConfirm')
					});
				});
			},
		},
	}
</script>

<style>
</style>
